import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import NosOffres from "../components/Offres/NosOffres"
import { Helmet } from "react-helmet"

const Offres = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Offres</title>
        <meta name="description" content="Seekreet, c’est une gamme d’offres claires. Des solutions de data management dès 99€, un accompagnement aussi bien sur la compliance, la qualité, la gestion client. Une équipe basée à Rennes qui vous..." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Offres" 
          homePageText="Accueil" 
          homePageUrl="/" 
          activePageText="Offres" 
      />
      <NosOffres />
      <Footer />
    </Layout>
  )
}

export default Offres