import React from 'react'
import { Link } from 'gatsby'
// import starIcon from '../../assets/images/star-icon.png'
// import icon1 from '../../assets/images/services/service-icon1.png'
// import icon2 from '../../assets/images/services/service-icon2.png'
// import icon3 from '../../assets/images/services/service-icon3.png'
// import icon4 from '../../assets/images/services/service-icon4.png'
import icon5 from '../../assets/images/services/service-icon5.png'
// import icon6 from '../../assets/images/services/service-icon6.png'
import shape1 from '../../assets/images/services/service-shape1.png'
import shape2 from '../../assets/images/services/service-shape2.png'
import dolibarr from '../../assets/images/offres/dolibarr.png'
import wispeek from '../../assets/images/offres/wispeek.png'
import capteurs from '../../assets/images/offres/capteurs.png'

const NosOffres = () => {
    return (
        <section className="services-area ptb-100 bg-wispeek">
            <div className="container">
                <div className="section-title">
                    <h2>Nos offres</h2>
                    <p>Découvrez les offres de nos différents services.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={wispeek} alt="wispeek" />
                            </div>
                            <h3>
                                <span>Wispeek</span>
                            </h3>
                            <p>Centralisez le pilotage de vos procédures en un seul outil</p>
                                
                            <Link to="/offre-wispeek" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={dolibarr} alt="dolibarr" />
                            </div>
                            <h3>
                                <span>ERP/CRM Dolibarr</span>
                            </h3>
                            <p>Maîtrisez votre démarche de culture de la qualité et de la sécurité des aliments</p>

                            <Link to="/offre-dolibarr" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>
                            
                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={capteurs} alt="capteurs" />
                            </div>
                            <h3>
                                <span>Capteurs</span>
                            </h3>
                            <p>Nous équipons vos locaux de différents capteurs, adaptés à vos besoins</p>
                            
                            <Link to="https://shop.wispeek.com/11-equipement" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus  <span></span>
                            </Link>
                            
                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon5} alt="sur mesure" />
                            </div>
                            <h3>
                                <span>Site internet</span>
                            </h3>
                            <p>Création & conception de sites internet professionnels personnalisés</p>
                            
                            <Link to="/site-internet" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>
                            
                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon5} alt="sur mesure" />
                            </div>
                            <h3>
                                <span>Projet sur mesure</span>
                            </h3>
                            <p>Développement particulier, conseils & préconisations</p>
                            
                            <Link to="/contact" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>
                            
                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </section>
    )
}

export default NosOffres